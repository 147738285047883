<template>
  <div>
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus kasus ${
        (showRemove && showRemove.worker && showRemove.worker.fullName) || 'Pekerja'
      } pada ${(showRemove && showRemove.client && showRemove.client.name) || '-perusahaan tidak ditemukan-'}`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            showRemove = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeCase(showRemove)
            showRemove = null
          },
        },
      ]"
    />
    <v-row>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$idCurrency($store.state.cases.kerugian)} Total Kerugian`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple-plus"
          color="green"
          :title="`${$idCurrency($store.state.cases.pengembalian)} Total Pengembalian`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-clock"
          color="orange"
          :title="`${$idCurrency($store.state.cases.hutang)} Total Hutang`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <atoms-text class="pa-2"><v-icon left>mdi-magnify</v-icon><span>Pencarian</span></atoms-text>
      <v-row justify="space-between">
        <v-col cols="12" md="8">
          <atoms-text-field
            label="Cari Kasus"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Kasus</atoms-button
          >
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button @click="showAssign = true" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-plus</v-icon>Tambah Kasus</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getCases()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort: {{ sort }}</atoms-button
          >
        </v-col>

        <v-col cols="12" md="auto">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.cases.data && $store.state.cases.data.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div
            v-for="({ worker, client, ...rest }, i) in $store.state.cases.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <v-avatar color="background rounded-lg">
                  <atoms-image :enableZoom="true" v-if="worker && worker.photo" :src="worker.photo" />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize((worker && worker.fullName && worker.fullName) || 'Pekerja')
                }}</atoms-text>
                <atoms-text class="primary--text">{{
                  (client && client.name && client.name) || 'Nama Perusahaan Tidak Terdefinisi'
                }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >Tanggal Kejadian {{ $moment(rest.tanggalKejadian).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
                <atoms-text class="primary--text"
                  >Disunting pada {{ $moment(rest._updatedDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
              </v-col>
              <v-col align="center" v-if="worker && worker.captain">
                <v-chip small class="rounded-lg" color="primary">Kapten</v-chip>
              </v-col>
              <v-col class="shrink">
                <v-badge
                  :value="rest.conversation.length"
                  :color="
                    rest.hadReadConversation && rest.hadReadConversation.some((_id) => _id === $store.state.user._id)
                      ? 'primary'
                      : 'red darken-1'
                  "
                  :content="rest.conversation.length"
                >
                  <v-icon
                    @click="
                      () => {
                        showConversation = rest._id
                      }
                    "
                    >mdi-forum</v-icon
                  >
                </v-badge>
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="showDetail = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-details</v-icon>
                      <v-list-item-title class="px-2">Detail</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showEdit = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-edit</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="$store.state.user.roles.includes($roles.Super_Admin)"
                      @click="showRemove = { worker, client, ...rest }"
                      class="rounded-lg px-4 text-center red white--text"
                    >
                      <v-icon left dark>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-container class="px-0 pb-0">
              <atoms-text><span class="font-weight-bold">Status : </span> {{ rest.status }}</atoms-text>
              <atoms-text
                ><span class="font-weight-bold">Kerugian</span> : Rp {{ $addSeparator(rest.kerugian) }}</atoms-text
              >
              <atoms-text
                ><span class="font-weight-bold">Pengembalian</span> : Rp
                {{ $addSeparator(rest.pengembalian) }}</atoms-text
              >
              <atoms-text
                ><span class="font-weight-bold">Hutang</span> : Rp {{ $addSeparator(rest.hutang) }}</atoms-text
              >
              <atoms-text class="font-weight-bold">Person Responsible : </atoms-text>
              <ul v-if="rest.personResp" class="text-left">
                <li v-for="(x, i) in rest.personResp" :key="i">
                  {{ x.nickname || '-' }}
                </li>
              </ul>
              <div v-if="rest.buktiBayar && rest.buktiBayar.length > 0">
                <atoms-text class="font-weight-bold">Bukti Bayar ke Hayokerja : </atoms-text>
                <ul>
                  <li class="mb-3" v-for="(it, id) in [].concat(rest.buktiBayar).reverse()" :key="id">
                    <div>{{ it.label }}</div>
                    <div>{{ $idCurrency(it.payAmount) }}</div>
                    <atoms-image
                      :enableZoom="true"
                      :src="it.value"
                      :contain="true"
                      minW="200"
                      maxW="500"
                      height="100"
                    />
                  </li>
                </ul>
              </div>
              <div v-if="rest.buktiBayarKeKlien && rest.buktiBayarKeKlien.length > 0">
                <atoms-text class="font-weight-bold">Bukti Bayar ke Klien : </atoms-text>
                <ul>
                  <li class="mb-3" v-for="(it, id) in [].concat(rest.buktiBayarKeKlien).reverse()" :key="id">
                    <div>{{ it.label }}</div>
                    <div>{{ $idCurrency(it.payAmount) }}</div>
                    <atoms-image
                      :enableZoom="true"
                      :src="it.value"
                      :contain="true"
                      minW="200"
                      maxW="500"
                      height="100"
                    />
                  </li>
                </ul>
              </div>
            </v-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.cases.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada kasus yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-cases-assign
        v-if="showAssign || showEdit || showDetail"
        :id="showEdit || showDetail"
        :readOnly="showDetail ? true : false"
        @closed="
          () => {
            showAssign = null
            showEdit = null
            showDetail = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-cases-conversation
        v-if="showConversation"
        :id="showConversation"
        @closed="
          () => {
            showConversation = null
            this.getCases()
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      showDetail: null,
      showAssign: null,
      showRemove: null,
      showEdit: null,
      showConversation: null,
      reportLoading: false,
      mainLoading: false,
      sort: '',
      constant: ['Desc', 'Asc'],
      filter: null,
      filters: [
        {
          title: 'Semua',
          value: {
            status: undefined,
          },
        },
        {
          title: this.$cases.working,
          value: {
            status: this.$cases.working,
          },
        },
        {
          title: this.$cases.stuck,
          value: {
            status: this.$cases.stuck,
          },
        },
        {
          title: this.$cases.done,
          value: {
            status: this.$cases.done,
          },
        },
      ],
    }
  },
  watch: {
    page: {
      handler() {
        this.getCases()
      },
      deep: true,
    },
  },
  async mounted() {
    this.filter = [this.filters[0].title]
    this.sort = this.constant[0]
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.getSummary()
      this.getCases()
      this.$vuetify.goTo(0)
    },
    pipeline() {
      const term = { $regex: this.term || '', $options: 'i' }
      return [
        ...this.$store.state.cases.defaultPipeline,
        {
          $sort: {
            _createdDate: this.sort === this.constant[0] ? -1 : 1,
          },
        },
        {
          $match: {
            ...(this.filter && this.filter.length > 0
              ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
              : []),
            $or: [
              {
                kerugian: term,
              },
              {
                hutang: term,
              },
              {
                'worker.idNumber': term,
              },
              {
                'worker.fullName': term,
              },
              {
                'worker.email': term,
              },
              {
                'worker.phone': term,
              },
              {
                'worker.birthplace': term,
              },
              {
                'worker.address.city': term,
              },
              {
                'worker.address.province': term,
              },
              {
                'worker.address.kecamatan': term,
              },
            ],
          },
        },
      ]
    },
    async removeCase(payload) {
      this.$store.commit('SET', { loading: true })
      try {
        if (payload?._id) {
          await this.$store.dispatch('cases/remove', payload?._id)
          this.$showDialog({
            title: 'Berhasil',
            body: `Kasus berhasil dihapus!`,
          })
          this.loadData()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('cases/summary', {
          custom: {
            pipeline: this.pipeline(),
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getCases() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('cases/getAll', {
          custom: {
            pipeline: this.pipeline(),
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
